import React from 'react'

const ChannelPage = (props) => {
    console.log(`ChannelPage.props: ${props}`)
    return (
        <div>
            <h1>{props.name}</h1>
            <h2>{props.description}</h2>
        </div>
    );
};

export default ChannelPage;