import React from 'react';
import Channel from './Channel';

const Channels = ({channels}) => {
    
    const channelStyle = {
        height: '450px',
        width: '450px',
    };
    
    return (
        <div>
            {channels.map(channel => (
                <Channel style={{channelStyle}} key={channel.name} name={channel.name} interfaces={channel.interfaces} />
            ))}
        </div>
    )
};

export default Channels;
