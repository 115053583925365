import React, { Fragment } from 'react';

// router...
import { 
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

// components...
import './../css/App.css';
import Navbar from './Navbar'; 
import Home from './Home';
import Applications from './Applications'
import Channels from './Channels'
import ChannelPage from './ChannelPage'

// prop data
const applicationProps=[
  {level: 'N4', language:'Japanese'},
  {level: 'N3', language:'Japanese'},
  {level: 'N2', language:'Japanese'},
  {level: 'N1', language:'Japanese'},
  {level: 'N4', language:'Italian'},
  {level: 'N3', language:'Italian'},
  {level: 'N2', language:'Italian'},
  {level: 'N1', language:'Italian'},
  {level: 'N4', language:'French'},
  {level: 'N3', language:'French'},
  {level: 'N2', language:'French'},
  {level: 'N1', language:'French'},
];

// for now just "pad" each object's interfaces property so that they all have the same number of values
// otherwise, the tiles will not be lined up properly.
const channelProps=[
  {makers: ['Apple'],   name: 'Smart Phone',    interfaces:['Voice', 'Screen', 'Notifications'],  description: 'All about SmartPhones'},
  {makers: ['Amazon'],  name: 'Smart Speaker',  interfaces:['Voice', 'Screen', ''],               description: 'All about SmartSpeakers'},
  {makers: ['Apple'],   name: 'Computer',       interfaces:['Screen', '', ''],                    description: 'All about Computers'},
  {makers: ['BMW'],     name: 'Car',            interfaces:['Notifications', '', ''],             description: 'All about Cars'},
  {makers: ['Apple'],   name: 'Smart Watch',    interfaces:['Screen', 'Notifications', ''],       description: 'All About Smart Watches'},
  {makers: [],          name: 'Projector',      interfaces:['Screen', '', ''],                    description: 'All About Projectors'},
];

const channelPageRoutes = channelProps.map((channel) => 
  // <Route exact path={`/channel/${channel.name}`} component={ChannelPage} />
  <Route
  path={`/channel/${channel.name}`}
  render={(props) => (
    <ChannelPage name={channel.name} description={channel.description}/>
  )}
/>
);

console.log('channelPageRoutes:', channelPageRoutes)

function App() {
  return (
    // put all routes here for the entire app
    // #refactor to a routes file
    // UI elements for navbar are defined in navbar.js, not here
    // ...but <Link>'s correclate to <Route>'s below
    // So, we can use this to link the tiles to their corresponding pages
    <Router>
      <div className="App">
        <Navbar />
          <div className="container">
            <Switch>
              {/* Create a route and point it to a component... */}
              <Route exact path='/' component={Home} />
              <Route 
                path='/applications'
                render={(props) => 
                  <Fragment>
                    <Applications applications={applicationProps} />
                  </Fragment>
                }
              />
              <Route 
                path='/channels'
                render={(props) => 
                  <Fragment>
                    <Channels channels={channelProps} />
                  </Fragment>
                }
              />
              {channelPageRoutes}
            </Switch>
          </div>
      </div>
    </Router>
  );
}

export default App;
