import React from 'react'

const Home = () => {
    return (
        <div>
            <h1>Welcome to Arazukuri</h1>
            <h2>What's Arazukuri?</h2>
            <p> Arazukuri lets you learn everywhere!</p>
        </div>
    )
}

export default Home;