import React from 'react';
import { Link } from 'react-router-dom'

// create a const for the fn that returns the component
const Navbar = ({title}) => {
    return (
        <nav className="navbar bg-primary">
            <h1>
                {title}
            </h1>
            <ul>
                <li>
                    <Link to='/applications'>Applications</Link>
                </li>
                <li>
                    <Link to='/channels'>Channels</Link>
                </li>
                <li>
                    <Link to='/'>Home</Link>
                </li>
            </ul>
        </nav>
    )
}

Navbar.defaultProps = {
    title: 'Arazukuri'
};

export default Navbar;
