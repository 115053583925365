import React from 'react';
import Application from './Application';

const Widgets = ({applications}) => {
    return (
        <div>
            {applications.map(application => (
                <Application key={`${application.language}-${application.level}`} language={application.language} level={application.level} />
            ))}
        </div>
    )
};

export default Widgets;
