import React from 'react';

const Channel = (props) => {

    const listItems = props.interfaces.map((i) =>
        <li key={props.name}><a href={`/channel/${props.name}`}>{i}</a></li>
        // <li ><a href={`/alexa`}>{i}</a></li>
    );

    console.log(listItems)

    return (
        <div className='widget'>
            <h1>{props.name}</h1>
            <h2>Interfaces:</h2>
            <ul>
                {listItems}
            </ul>
        </div>
    );
};

export default Channel;